import api from "./utils/api";

const state = {
    status: 'loading',
    loading: true,
    list: []
};

const actions = {
    getOrders({dispatch, commit},showLoader) {
        if(showLoader == true) {
            commit('gettingOrders')
        }
        return new Promise((resolve) => {
            api.get('/orders/list', null)
                .then(response => {
                    commit('gotOrders', response.body.Results)
                    resolve(response);
                }).catch(error => {
                dispatch('alert/error', 'Error fetching order list', {root: true});
                state.status = null;
                console.log(error)
            });
        });
    }
};

const mutations = {
    gettingOrders(state) {
        state.loading = true;
        state.list = [];
    },
    gotOrders(state, orders) {
        state.list = orders;
        state.status = null;
        state.loading = false
    },
};

export const orders = {
    namespaced: true,
    state,
    actions,
    mutations,
    getters: {
    }
};