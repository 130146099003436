import VueRouter from 'vue-router'
import orders from "../pages/orders";
import order from "../pages/order";
import orderProduct from "../pages/order-product";
import index from '../pages/index'
import login from "../pages/login";
import settings from "../pages/settings";
import Vue from "vue";
import {store} from '../_store';

Vue.use(VueRouter);

const routes = [
    {path: '/', component: index},
    {path: '/orders', component: orders},
    {path: '/order/:id', component: order},
    {path: '/order-product/:id', component: orderProduct},
    {path: '/login', component: login},
    {path: '/settings', component: settings},
    // otherwise redirect to home
    {path: '*', redirect: '/'}
]


const router = new VueRouter({
    routes // short for `routes: routes`
});

router.beforeEach((to, from, next) => {
    // redirect to login page if not logged in and trying to access a restricted page
    const publicPages = ['/login'];
    const authRequired = !publicPages.includes(to.path);
    const loggedIn = store.state.account.user;

    if (authRequired && !loggedIn) {
        console.log('User Idle on path:' + router.currentRoute.path)

        var redirect = '/test';
        if(router.currentRoute.path != '/login') {
            redirect = router.currentRoute.path != '/' ? router.currentRoute.path : null;
        }
        console.log(router.currentRoute.path);
        return next({
            path: '/login',
            query: {redirect: redirect}
        });
    }

    next();
})

export default router