import Vue from 'vue';
import resource from "vue-resource";
import App from './App.vue'
import router from "./_helpers/router";
import './assets/tailwind.css'
import './css/index.scss';
import {store} from './_store';
import VueFilter from 'vue-filter';

import { FontAwesomeIcon, FontAwesomeLayers, FontAwesomeLayersText } from '@fortawesome/vue-fontawesome'

import {library} from '@fortawesome/fontawesome-svg-core'
import {faUserSecret} from '@fortawesome/free-solid-svg-icons'
import {faCubes, faBars} from '@fortawesome/free-solid-svg-icons'
import {faCheckCircle} from '@fortawesome/free-solid-svg-icons'
import {faCross, faSearch, faPeopleCarry, faBoxOpen, faBarcode, faPrint, faChevronCircleLeft, faChevronCircleRight, faUser, faCheck,faTimes,faChevronLeft, faChevronDown, faChevronUp, faCogs,faShoppingBasket,faHome, faSignOutAlt} from '@fortawesome/free-solid-svg-icons'
import {faCircle} from '@fortawesome/free-regular-svg-icons'


library.add(faCross, faSearch, faPeopleCarry, faCubes, faBoxOpen, faBarcode, faPrint, faChevronCircleLeft, faChevronCircleRight, faUser, faCheck,faTimes,faChevronLeft,faCircle,faCheckCircle,faBars,faUserSecret,faChevronDown,faChevronUp,faCogs,faHome,faShoppingBasket,faSignOutAlt)

Vue.component('font-awesome-layers-text', FontAwesomeLayersText)
Vue.component('font-awesome-layers', FontAwesomeLayers)
Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.use(resource);
Vue.use(VueFilter);

Vue.config.productionTip = false

import IdleVue from "idle-vue";

const eventsHub = new Vue();

Vue.use(IdleVue, {
    eventEmitter: eventsHub,
    store,
    idleTime: 1000*store.state.settings.idleTimeout, //1000 MS * Seconds in settings,
    startAtIdle: false
});

new Vue({
    router,
    store,
    render: h => h(App),
}).$mount('#app')
