<template>
    <div class="h-screen overflow-scroll">
        <div class="pageWrapper w-screen min-h-screen bg-gray-300">
            <slot></slot>
        </div>
    </div>
</template>

<script>
    export default {
        name: "layoutNoMenu",
        data() {
            return {

            }
        },
    }
</script>

<style scoped>

</style>