<template>
    <layout-no-menu title="Login">
        <div class="pt-4 p-2">
            <div class="z-10 p-4 mx-auto bg-white shadow-lg" :class="[input ? 'w-full sm:w-2/3' : 'w-full md:w-96']">
                <div class="flex w-full">
                    <div :class="[input ? 'w-full sm:w-1/2' : 'w-full']">
                        <div class="w-full pt-2">
                            <div class="mx-auto bg-gray-400 text-white rounded-full h-32 w-32 flex items-center justify-center"><font-awesome-icon icon="user" size="4x"/></div>
                        </div>
                        <div>
                            <div class="label">
                                User Id:
                            </div>
                            <input v-on:focus="onInputChange('username')" ref="username" type="text" size="10"
                                   class="w-full rounded-lg p-2 h-20 text-center text-4xl" v-model="username" pattern="\d*">
                        </div>
                        <div class="w-full">
                            <div class="label">
                                Pass Code:
                            </div>
                            <div class="w-full flex">

                                    <input type="password" name="pin[]" size="1" maxlength="1"
                                           v-on:focus="onInputChange('pin1')"
                                           v-on:keyup="pinKeyUp" ref="pin1"
                                           class="password-pin rounded-lg p-2 h-20 mr-2 text-center appearance-none"
                                           v-model="pin1" pattern="\d*">

                                    <input type="password" name="pin[]" size="1" maxlength="1"
                                           v-on:focus="onInputChange('pin2')"
                                           v-on:keyup="pinKeyUp" ref="pin2"
                                           class="password-pin rounded-lg p-2  h-20 mr-2 text-center appearance-none "
                                           v-model="pin2" pattern="\d*">

                                    <input type="password" name="pin[]" size="1" maxlength="1"
                                           v-on:focus="onInputChange('pin3')"
                                           v-on:keyup="pinKeyUp" ref="pin3"
                                           class="password-pin rounded-lg p-2  h-20 mr-2 text-center appearance-none"
                                           v-model="pin3" pattern="\d*">

                                    <input type="password" name="pin[]" size="1" maxlength="1" ref="pin4"
                                           class="password-pin rounded-lg p-2 h-20 text-center appearance-none"
                                           v-on:focus="onInputChange('pin4')" v-model="pin4" pattern="\d*">
                            </div>
                        </div>
                    </div>
                    <div v-if="input" class="hidden md:flex p-4">
                        <div class="flex flex-wrap rounded-lg bg-gray-200 p-2">
                            <div class="keyboard-button" v-for="key in keys" :key="key">
                                <button class="btn" @click="press(key)">{{ key }}</button>
                            </div>
                        </div>
                    </div>
                </div>
                <button v-on:click="handleSubmit" type="submit" class="btn w-full">Login</button>
            </div>

        </div>

    </layout-no-menu>
</template>

<script>
    import {mapState, mapActions} from 'vuex'
    import LayoutNoMenu from "../components/layoutNoMenu";

    export default {
        name: "orders",
        components: {LayoutNoMenu},
        data() {
            return {
                keys: [1, 2, 3, 4, 5, 6, 7, 8, 9, 0],
                username: '',
                password: '',
                pin1: '',
                pin2: '',
                pin3: '',
                pin4: '',
                submitted: false,
                input: null,
            }
        },
        computed: {
            ...mapState('account', ['status'])
        },
        created() {
            // reset login status
            this.logout();
        },
        methods: {
            ...mapActions('account', ['login', 'logout']),
            press(button) {

                this[this.input] = (this[this.input] ? this[this.input] : '') + String(button);

                if (this.input == 'pin4') {
                    this.input = null;
                }

                var skipToNextFieldArray = ['pin1', 'pin2', 'pin3']
                if (skipToNextFieldArray.includes(this.input)) {
                    this.$refs[this.input].nextElementSibling.focus();
                }

            },
            handleSubmit(e) {
                if (e) e.preventDefault();
                this.password = this.pin1 + "" + this.pin2 + "" + this.pin3 + "" + this.pin4;

                this.submitted = true;
                const {username, password} = this;

                var redirect = this.$route.query.redirect || '/';
                if (username && password) {
                    this.login({username: username, password: password, redirect: redirect})
                }
            },
            pinKeyUp(input) {
                if (input.target.value != '') {
                    if (input.target.nextElementSibling) {
                        input.target.nextElementSibling.focus()
                    } else {
                        this.input = null;
                    }
                }
            },
            onInputChange(model) {
                this.input = model;
                this[model] = null;
            }
        }
    }
</script>

<style scoped lang="scss">
    .btn {
        @apply border-2 rounded-lg border-orange-600 bg-white text-orange-600 px-4 py-3 mt-4 text-center;
    }

    .password-pin {
        -webkit-text-security: disc;
        @apply w-full;
    }

    .keyboard-button {
        @apply w-1/3 h-24 p-2;
        button {
            @apply w-full h-full mt-0 border-gray-400 text-gray-600;
        }
    }

    .label {
        @apply text-orange-600 py-4 uppercase;
    }

    input {
        @apply border-orange-600 border-2;
    }

    input[type=number]::-webkit-inner-spin-button,
    input[type=number]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
</style>