<template>
    <div class="box relative h-full"
         :class="[{'success':colour=='success'},{'danger':colour=='danger'},{'highlighted':highlighted}]">
        <div class="content w-full" :class="
                    [
                    {'open':expanded},
                    {'minimised':minimised},
                    {'h-full':!expanded && minimised},
                    {'h-full':!minimised},
                    ]">
            <div class="flex h-full w-full">
                <div class="flex-1 w-full">
                    <div v-if="title" class="title" :class="{'absolute':titleAbsolute}">{{title}}</div>
                    <div class="disabled-overlay" v-if="disabled"></div>
                    <div class="body">
                        <template v-if="expandedDataExtraDataSlot == true">
                            <div v-if="!expanded" class="break-all h-full w-full"
                                 :class="[{'-mt-6 pt-6':!titleAbsolute},{'flex items-center justify-center':absoluteCenter}]">
                                <slot></slot>
                            </div>
                            <slot name="extra-data" v-if="expanded && minimised"></slot>
                        </template>
                        <template v-else>
                            <div class="break-all h-full w-full"
                                 :class="[{'-mt-6 pt-6':!titleAbsolute},{'flex items-center justify-center':absoluteCenter}]">
                                <slot></slot>
                            </div>
                        </template>
                    </div>
                    <div v-if="minimised" class="bottom-button" v-on:click="expanded = !expanded">
                        <font-awesome-icon :icon="!expanded ? 'chevron-down' : 'chevron-up'"/>
                    </div>
                </div>
                <div class="box-status" v-if="confirmationBox && confirmationAnswer">
                    <span class="text-green-600"><font-awesome-icon icon="check-circle"/></span>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
    export default {
        name: "contentBox",
        props: {
            minimised: Boolean,
            title: String,
            titleAbsolute: {
                type: Boolean,
                default: false
            },
            classText: String,
            colour: String,
            expandedDataExtraDataSlot: Boolean,
            absoluteCenter: Boolean,
            disabled: {
                type: Boolean,
                default: false
            },
            highlighted: Boolean,
            confirmationBox: {
                type: Boolean,
                default: false
            },
            confirmationAnswer: {
                type: Boolean,
                default: false
            },
        },
        data() {
            return {
                expanded: false
            }
        }
    }
</script>

<style lang="scss">
    .box {

        .title {
            @apply text-base text-orange-600 uppercase;
            &.absolute {
                @apply px-2 pt-1 absolute top-0 left-0;
            }
        }

        .content {
            @apply p-2 bg-white w-full rounded-lg shadow-xl overflow-hidden;
            .bottom-button {
                @apply rounded-full h-12 w-12 flex items-center justify-center bg-orange-600 absolute text-white cursor-pointer;
                left: 50%;
                margin-left: -24px;
                bottom: -20px;
                z-index: 50;
            }

            &.minimised {
                @apply border-b-4 border-orange-600 pb-0;
            }

            &.open.minimised {
                @apply absolute z-10 pb-6 border-b-4 border-gray-500 overflow-visible;
                .bottom-button {
                    @apply bg-gray-500;
                    bottom: -24px;
                }
            }

            .body {
                @apply overflow-hidden h-full flex;
            }

        }

        .disabled-overlay {
            @apply absolute bg-white w-full h-full opacity-50 rounded-lg -mt-2 -ml-2;
        }

        &.highlighted {
            @apply border-teal-600 border-4;
            border-radius: 12px;

            .content {
                @apply text-teal-600;
            }

            .title {
                @apply text-teal-600;
            }
        }
    }


    .box.danger {
        @apply text-red-700;
        .title {
            @apply text-red-800;
        }

        .content {
            @apply bg-red-300 border-red-300;
        }
    }

    .box.success {
        @apply text-green-700;
        .title {
            @apply text-green-800;
        }

        .content {
            @apply bg-green-300 border-green-300;
        }
    }
</style>