import printerApi from "./utils/printer-api";

const state = {
    status: null
};

const actions = {
    clearDefaultPrinter({commit}) {
        commit('settings/saveValue', {label: 'printer', value: null}, {root: true})
    },
    setDefaultPrinter({commit}) {
        printerApi.get('default?type=printer')
            .then(response => {
                var responseData = JSON.stringify(response.body);
                commit('settings/saveValue', {label: 'printer', value: JSON.parse(responseData)}, {root: true})
            }).catch(error => {
            commit('settings/saveValue', {label: 'printer', value: null}, {root: true})
            console.log(error);
        });
    },
    completeOrderSticker({commit, getters, rootState}, stockerData) {

        var data = null;
        data = data + '^XA\n' +
            getters.FO(0, 0) + '^GB385,40,40,,0^FS\n' +
            '^CF0,30,30' + getters.FO(0, 10) + '^FB385,6,,C^FR^FDORDER PACKAGE^FS' +
            getters.FO(50,50) + '^BY2\n' +
            '^BCN,70,Y,N,N\n' +
            '^FDORD-'+stockerData.order.id+'^FS' +
        getters.FO(0, 0) + '^GB385,185,3,,0^FS' +
            getters.FO(0, 150) + '^GB385,2,3,,0^FS\n' +
        getters.FO(10, 160) + '^CF0,20,20^FB385,6,,L^FD'+stockerData.order.deliveryMethod+'^FS';
           // getters.FO(10, 140) + '^CF0,20,20^FB385,6,,L^FDITEMS PACKED:   '+stockerData.order.itemsPicked+'^FS';
        data = data + '^XZ';

        var body = {
            device: {
                name: rootState.settings.printer.name,
                uid: rootState.settings.printer.uid,
                connection: rootState.settings.printer.connection,
                deviceType: rootState.settings.printer.deviceType,
                version: rootState.settings.printer.version,
                provider: rootState.settings.printer.provider,
                manufacturer: rootState.settings.printer.manufacturer
            }, data: data
        };

        printerApi.post('write', JSON.stringify(body)).then(() => {
            commit('printed')
        }).catch((error) => {
            console.log(error)
        });

    },
    sendToPrinter({commit, getters, rootState}, stickerData) {
        commit('printing')

        var data = null;

        for (let i = 1; i <= Number(stickerData.bags); i++) {
            data = data + '^XA\n' +
                getters.FO(0, 0) + '^GB385,185,3,,0^FS\n' +

                getters.FO(0, 0) + '^GB230,70,3,,0^FS\n' +

                getters.FO(228, 0) + '^GB157,40,3,,0^FS\n' +
                getters.FO(228, 37) + '^GB157,148,3,,0^FS\n' +
                getters.FO(235, 12) + '^A0,25,25^FB145,1,,C^FD' + stickerData.partNumber + '^FS\n';

            if (stickerData.bin != null) {
                if (stickerData.bin[0]) {
                    var width = 0;
                    if (stickerData.bin[0].length <= 2) {
                        width = 40;
                    } else if (stickerData.bin[0].length <= 4) {
                        width = 30;
                    } else if (stickerData.bin[0].length > 4) {
                        width = 20;
                    }
                    data = data + getters.FO(0, 0) + '^GB77,70,35,,0^FS\n';
                    data = data + getters.FO(0, 15) + '^A0,50,' + width + '^FB75,1,,C^FR^FD' + stickerData.bin[0] + '^FS\n';
                }

                if (stickerData.bin[1]) {
                    width = 0;
                    if (stickerData.bin[1].length <= 2) {
                        width = 40;
                    } else if (stickerData.bin[1].length <= 4) {
                        width = 30;
                    } else if (stickerData.bin[1].length > 4) {
                        width = 20;
                    }
                    data = data + getters.FO(77, 0) + '^GB77,70,1,,0^FS\n';
                    data = data + getters.FO(85, 15) + '^A0,50,' + width + '^FB75,1,,C^FD' + stickerData.bin[1] + '^FS\n';
                }

                if (stickerData.bin[2]) {
                    width = 0;
                    if (stickerData.bin[2].length <= 2) {
                        width = 40;
                    } else if (stickerData.bin[2].length <= 4) {
                        width = 30;
                    } else if (stickerData.bin[2].length > 4) {
                        width = 20;
                    }
                    data = data + getters.FO(152, 0) + '^GB77,70,1,,0^FS\n';
                    data = data + getters.FO(156, 15) + '^A0,50,' + width + '^FB75,1,,C^FD' + stickerData.bin[2] + '^FS\n';
                }
            }
            data = data + getters.FO(0, 155) + '^GB230,2,1,,0^FS\n';
            data = data + '^CF0,20,20' + getters.FO(7, 162) + '^FB125,1,,L^FDID:^FS'
            data = data + '^CF0,20,20' + getters.FO(70, 162) + '^FB155,1,,R^FD' + stickerData.orderID + '^FS'
            data = data + '^CF0,15,15' + getters.FO(10, 80) + '^FB215,4,,^FD' + stickerData.productTitle + '^FS' +

                getters.FO(235, 45) + '^A0,15,15^FR^FDORDERED^FS\n' +
                getters.FO(300, 43) + '^A0,20,20^FB80,1,,R^FD' + stickerData.ordered + '^FS\n' +
                getters.FO(230, 61) + '^GB155,1,1,,0^FS\n' +

                getters.FO(235, 66) + '^A0,15,15^FR^FDSENT^FS\n' +
                getters.FO(300, 64) + '^A0,20,20^FB80,1,,R^FD' + stickerData.sent + '^FS\n' +
                getters.FO(230, 81) + '^GB155,1,1,,0^FS\n' +

                getters.FO(235, 86) + '^A0,15,15^FR^FDBO^FS\n' +
                getters.FO(300, 84) + '^A0,20,20^FB80,1,,R^FD' + stickerData.backOrdered + '^FS\n' +
                getters.FO(230, 101) + '^GB155,1,1,,0^FS\n' +

                getters.FO(235, 106) + '^A0,15,15^FR^FDCAN^FS\n' +
                getters.FO(300, 104) + '^A0,20,20^FB80,1,,R^FD' + stickerData.cancelled + '^FS\n' +
                getters.FO(230, 121) + '^GB155,1,1,,0^FS\n' +

                getters.FO(235, 126) + '^A0,15,15^FR^FDBAGS^FS\n' +
                getters.FO(300, 124) + '^A0,20,20^FB80,1,,R^FD' + i + ' of ' + stickerData.bags + '^FS\n' +
                getters.FO(230, 141) + '^GB155,1,1,,0^FS\n';

            if (stickerData.customerPartNumber != null) {
                data = data +
                    getters.FO(230, 142) + '^GB155,40,25,,0^FS\n' +
                    getters.FO(235, 150) + '^A0,30,20^FB145,1,,C^FR^FD' + stickerData.customerPartNumber + '^FS\n';
            }

            data = data + '^XZ';
        }

        var body = {
            device: {
                name: rootState.settings.printer.name,
                uid: rootState.settings.printer.uid,
                connection: rootState.settings.printer.connection,
                deviceType: rootState.settings.printer.deviceType,
                version: rootState.settings.printer.version,
                provider: rootState.settings.printer.provider,
                manufacturer: rootState.settings.printer.manufacturer
            }, data: data
        };

        printerApi.post('write', JSON.stringify(body)).then(() => {
            commit('printed')
        }).catch((error) => {
            console.log(error)
        });
    }

};

const mutations = {
    printing(state) {
        state.status = 'printing'
    },
    printed(state) {
        state.status = 'success'
    }
}

export const printer = {
    namespaced: true,
    state,
    actions,
    mutations,
    getters: {
        'FO': (state, getters, rootState) => (x, y) => {
            var offsetX = rootState.settings.stickerOffsetX;
            var offsetY = rootState.settings.stickerOffsetY;
            return '^FO' + (Number(offsetX) + x) + ',' + (Number(offsetY) + y);
        }
    }
};