import Vue from 'vue'
/*
http: {
        root: 'https://3dgroupuk.com/api',
        headers: {
            APIKEY: 'sssdafsdfseqfw'
            //Authorization: 'Basic YXBpOnBhc3N3b3Jk'
        }
    }
 */
export default {
    get(url, request) {
        Vue.http.headers.common = [];
        return Vue.http.get('http://localhost:9100/' + url, request)
            .then((response) => Promise.resolve(response))
            .catch((error) => Promise.reject(error));
    },
    post(url, request) {
        Vue.http.headers.common = [];

        return Vue.http.post('http://localhost:9100/' + url, request,{headers: {
                'Content-Type': 'text/plain;charset=UTF-8'
            }})
            .then((response) => Promise.resolve(response))
            .catch((error) => Promise.reject(error));
    },
    patch(url, request) {
        return Vue.http.patch(url, request)
            .then((response) => Promise.resolve(response))
            .catch((error) => Promise.reject(error));
    },
    delete(url, request) {
        return Vue.http.delete(url, request)
            .then((response) => Promise.resolve(response))
            .catch((error) => Promise.reject(error));
    }
}