<template>
    <div class="h-screen overflow-y-auto h-full" @scroll="$emit('scrolling')">
        <div class="menuWrapper bg-orange-400 w-full h-screen absolute" v-if="menuToggle">
            <div class="bg-white mt-24">
                <div class="border-b-2 h-32 border-gray-300 flex text-orange-600 pt-8">
                    <div class="text-center w-32">
                        <router-link to="/">
                            <font-awesome-icon icon="home" size="2x"/>
                            <br>
                            home
                        </router-link>
                    </div>
                </div>
                <div class="border-b-2 h-32 border-gray-300 flex text-orange-600 pt-8">
                    <div class="text-center w-32">
                        <router-link to="/orders">
                            <font-awesome-icon icon="shopping-basket" size="2x"/>
                            <br>
                            Orders
                        </router-link>
                    </div>
                </div>
                <div class="border-b-2 h-32 border-gray-300 flex text-orange-600 pt-8">
                    <div class="text-center w-32">
                        <router-link to="/settings">
                            <font-awesome-icon icon="cogs" size="2x"/>
                            <br>
                            Settings
                        </router-link>
                    </div>
                </div>
                <div class="border-b-2 h-32 border-gray-300 flex text-orange-600 pt-8">
                    <div class="text-center w-32">
                        <router-link to="/login">
                            <font-awesome-icon icon="sign-out-alt" size="2x"/>
                            <br>
                            Log out
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
        <div class="pageWrapper w-full min-h-screen"
             :class="[{'ml-32 mt-4 z-10 mb-4 relative shadow':menuToggle},bgColourClass]">
            <div v-if="loading" class="loading">
                  <span class="text-orange-500 opacity-75 top-1/2 my-0 mx-auto block relative w-0 h-0"
                        style="top: 50%;">
                    <i class="fas fa-circle-notch fa-spin fa-5x"></i>
                  </span>
            </div>
            <div class="relative z-0">
                <div class="h-24 w-full bg-orange-500 text-center text-white flex">
                    <div class="p-4" @click="menuToggle = !menuToggle">
                        <font-awesome-icon icon="bars" size="2x"/>
                    </div>
                    <div class="flex-1 p-2 title">
                        <div v-html="title"></div>
                        <div v-if="updated">
                            <span class="updated">Updated
                                <time-ago class="text-white" :refresh="10" :datetime="updated" long></time-ago>
                            </span>
                        </div>
                    </div>
                    <template v-if="backButton != false">
                        <div v-if="backButton == '-1'" class="back-button p-3 pr-0" v-on:click="$router.go(backButton)">

                            <font-awesome-layers class="fa-2x">
                                <font-awesome-icon :icon="['far', 'circle']"/>
                                <font-awesome-icon icon="chevron-left" transform="shrink-7" class="pr-1"/>
                            </font-awesome-layers>

                        </div>
                        <div v-if="backButton != '-1'" class="back-button p-3 pr-0"
                             v-on:click="$router.push(backButton)">
                            <font-awesome-icon icon="chevron-left" size="2x"/>
                        </div>
                    </template>
                </div>
                <div class="bg-orange-500">
                    <slot name="header"></slot>
                </div>
                <div class="">
                    <svg class="text-orange-500 fill-current absolute" viewBox="0 0 1428 100" version="1.1"
                         xmlns="http://www.w3.org/2000/svg"
                         xmlns:xlink="http://www.w3.org/1999/xlink">
                        <g fill-rule="nonzero">
                            <path d="M 0 0 Q 714 100 1428 0"></path>
                        </g>
                    </svg>
                </div>
            </div>
            <div class="relative z-10">
                <slot></slot>
            </div>
        </div>
    </div>
</template>

<script>
    import TimeAgo from 'vue2-timeago'

    export default {
        name: "layout",
        components: {TimeAgo},
        props: {
            'backButton': {
                default: false
            },
            'title': {
                default: null
            },
            'bgColour': {
                default: 'gray-300'
            },
            'loading': {
                default: false
            },
            'updated': {
                default: null
            }
        },
        data() {
            return {
                menuToggle: false,
                bgColourClass: 'bg-' + this.bgColour
            }
        }
    }
</script>

<style lang="scss">
    .loading {
        @apply w-full h-full fixed block top-0 left-0 bg-white opacity-75 z-50;
    }

    .back-button {
        @apply bg-white h-16 w-16 text-orange-500 shadow-inner cursor-pointer;
        -webkit-border-bottom-left-radius: 50px;
        -moz-border-radius-bottomleft: 50px;
        border-bottom-left-radius: 50px;
    }

    .title .updated {
        @apply text-orange-400 bg-orange-600 py-1 px-2 rounded-full text-xs;
        .v-time-ago__text {
            @apply text-orange-400 capitalize text-xs;
        }
    }
</style>