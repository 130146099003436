<template>
    <layout :title="title" :back-button="backButtonLink"
            :loading="loading==true">
        <div class="lg:flex">
            <div class="w-full lg:w-2/5 hidden lg:block -mt-6">
                <div class="pl-2">
                    <content-box title="Items To Pick"
                                 v-if="order != null && selectedProduct != null">

                            <div v-for="productLine in order.items" :key="productLine.id">
                                <router-link :to="'/order-product/' + productLine.id">
                                    <div class="py-1">
                                        <div class="border rounded border-gray-400 flex hover:bg-gray-300">
                                            <div class="p-3 flex-1 truncate">
                                                    <span class="text-orange-600">{{productLine.bin_location}} </span>
                                                    <span class="text-black">{{productLine.SKU}}</span>
                                                    {{productLine.name}}
                                            </div>
                                            <div>
                                                <template v-if="productLine.itemsPicked == productLine.ordered">
                                                    <div class="box-status-product-line">
                                                        <span class="text-green-600"><font-awesome-icon
                                                                icon="check-circle"/></span>
                                                    </div>
                                                </template>
                                                <template v-else>
                                                    <div class="p-3 text-gray-600">
                                                        Packed: {{productLine.itemsPicked}} of {{productLine.ordered}}
                                                    </div>
                                                </template>
                                            </div>
                                        </div>
                                    </div>
                                </router-link>
                            </div>
                    </content-box>
                </div>
            </div>

            <div class="w-full lg:w-3/5 content z-10 -mt-6 p-2 py-0" v-if="selectedProduct != null">
                <div class="flex w-full flex-wrap">
                    <div class="pb-2 pr-2 w-1/2 md:w-1/3 text-2xl text-center h-32">
                        <content-box title="SKU" :title-absolute="true" :vertically-align="true"
                                     :absolute-center="true">
                            {{selectedLineProduct.SKU}}
                        </content-box>
                    </div>
                    <div class="pb-2 md:pr-2 w-1/2 md:w-1/3 text-2xl text-center h-32"
                         v-if="selectedProduct.stockLevel.relies_on_others == 0">
                        <content-box title="BIN" :title-absolute="true"
                                     :absolute-center="true"
                                     :vertically-align="true"
                                     :expanded-data-extra-data-slot="true"
                                     :minimised="selectedLineProduct.stockLocations.length > 0">
                            {{selectedLineProduct.Bin_location}}
                            <template v-slot:extra-data>
                                <div class="w-full">
                                    <div class="p-4">{{selectedLineProduct.Bin_location}}</div>
                                    <div class="title py-2">Additional Locations</div>
                                    <div class="border p-4" v-for="location in selectedLineProduct.stockLocations"
                                         :key="location.id">
                                        {{location.location}}
                                    </div>
                                </div>
                            </template>
                        </content-box>
                    </div>
                    <div class="md:pb-2 w-1/2 md:w-1/3 text-2xl text-center h-32"
                         :class="{'pr-2 md:pr-0':selectedProduct.stockLevel.relies_on_others == 0}">
                        <content-box title="Qty" :title-absolute="true"
                                     :vertically-align="true"
                                     :highlighted="true"
                                     :colour="enoughStock ? null : 'danger'" :absolute-center="true">
                            {{selectedProduct.line_item.quantity}}
                        </content-box>
                    </div>
                    <div class="md:pr-2 mb-2 w-1/2 md:w-1/3 text-2xl text-center h-32"
                         v-if="selectedProduct.stockLevel.relies_on_others == 0">
                        <content-box title="IMAGE" :title-absolute="true" :vertically-align="true"
                                     :absolute-center="true"
                                     v-if="selectedLineProduct.productImages && selectedLineProduct.productImages[0]">
                            <img class="object-contain h-24 w-full"
                                 :src="selectedLineProduct.productImages[0].url"/>
                        </content-box>
                    </div>

                    <template v-if="selectedProduct.stockLevel.relies_on_others == 0">
                        <div class="pr-2 mb-2 w-1/2 md:w-1/3 text-2xl text-center h-32">
                            <content-box title="Packed" :title-absolute="true"
                                         :vertically-align="true" :absolute-center="true">
                                {{packed}} / {{selectedProduct.line_item.quantity}}
                            </content-box>
                        </div>
                        <div class="mb-2 w-1/2 md:w-1/3 text-2xl text-center h-32">
                            <content-box title="Stock Level" :title-absolute="true"
                                         :vertically-align="true" :absolute-center="true">
                                {{selectedProduct.stockLevel.stock_level}}
                                ({{selectedProduct.stockLevel.amounts_in_order}})
                            </content-box>
                        </div>
                    </template>
                    <template v-else-if="selectedProduct.stockLevel.relies_on_others == 1">
                        <div class="w-full md:w-full text-2xl text-center pb-4 h-48">
                            <content-box title="Required Products" :title-absolute="true"
                                         :minimised="selectedProduct.stockLevelRelies.length>2">
                                <div class="w-full pt-8 text-left whitespace-no-wrap">
                                    <div class="text-base pt-1 w-full">
                                        <div class="px-1 flex w-full text-orange-600 text-xs">
                                            <div class="px-2 w-10 text-center">QTY</div>
                                            <div class="px-2 w-16 text-center">SKU</div>
                                            <div class="px-2 w-16 text-center">BIN</div>
                                            <div class="px-2 w-16">TOTAL</div>
                                            <div class="px-2 flex-1 text-right">PICKED</div>
                                        </div>
                                    </div>
                                    <div class="text-base pt-1 w-full"
                                         v-for="(reliedOnProduct,key) in selectedProduct.stockLevelRelies"
                                         :key="reliedOnProduct.product.id" v-on:click="updateReplyingProduct(key)">
                                        <div class="flex p-2 rounded-lg border w-full related-product"
                                             :class="{'complete':selectedProductExtraInfo.requiredExtraProducts[key]}">
                                            <div class="px-2 w-10 text-center">
                                                {{selectedProduct.stockLevelRelies[key].complete}}
                                                {{reliedOnProduct.relationDetails.qty}}
                                            </div>
                                            <div class="px-2 w-16 text-center">{{reliedOnProduct.product.SKU}}</div>
                                            <div class="px-2 w-16 text-center">{{reliedOnProduct.product.Bin_location}}
                                            </div>
                                            <div class="px-2 w-16">
                                                {{reliedOnProduct.relationDetails.qty*selectedProduct.line_item.quantity}}
                                            </div>
                                            <div class="flex-1 text-right icon">{{reliedOnProduct.picked}}
                                                <font-awesome-icon icon="check-circle" size="lg"/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </content-box>
                        </div>
                    </template>
                </div>
                <div v-on:click="markSelectedProductNotes" v-if="selectedLineProduct.productPickingNotes">
                    <div class="pb-2">
                        <content-box title="Picking Notes"
                                     :colour="confirmedProductNotes ? 'success' : 'danger'"

                                     :confirmation-box="true"
                                     :confirmation-answer="confirmedProductNotes">
                            <div v-html="$options.filters.nl2br(selectedLineProduct.productPickingNotes.notes)">
                            </div>
                        </content-box>
                    </div>
                </div>
                <div class="pb-4">
                    <content-box :title="'PACKED: ' +packed+' /' + selectedProduct.line_item.quantity"
                                 :title-absolute="true" class-text="w-full mb-2" :vertically-align="true"
                                 :disabled="order.locked == 1">
                        <div class="w-full">
                            <div class="mx-auto pt-4" :class="{'error':lineItemUpdateStatus=='error'}">
                                <div class="sm:flex mx-auto">
                                    <div class="flex-1 md:py-5 text-xl uppercase">Packed:</div>
                                    <div class="sm:w-3/4 flex p-1">
                                        <div class="picking-button rounded-l border-l hidden md:block"
                                             v-on:click="decreaseLineItemPickingValues({val: 0, item:'packed_quantity'})">
                                            ZERO
                                        </div>
                                        <div class="picking-button  border-l md:border-l-0 rounded-l md:rounded-none"
                                             v-on:click="decreaseLineItemPickingValues({val: 1, item:'packed_quantity'})">
                                            -
                                        </div>
                                        <div class="picking-input flex-1"><input
                                                v-model.number="selectedProduct.line_item.packed_quantity"
                                                v-on:change="updateOrderLineItem"
                                                class="w-full h-full text-center bg-gray-100"
                                                type="text"></div>
                                        <div class="picking-button"
                                             v-on:click="increaseLineItemPickingValues({val: 1, item:'packed_quantity'})">
                                            +
                                        </div>
                                        <div class="w-20 picking-button rounded-r"
                                             v-on:click="increaseLineItemPickingValues({val: maxAvailableToPack, item:'packed_quantity'})">
                                            MAX
                                        </div>
                                    </div>
                                </div>
                                <div class="sm:flex mx-auto">
                                    <div class="flex-1 md:py-5 text-xl uppercase text-orange-400">B-Ordered:</div>
                                    <div class="sm:w-3/4 flex p-1">
                                        <div class="picking-button rounded-l border-l hidden md:block"
                                             v-on:click="decreaseLineItemPickingValues({val: 0, item:'back_order_quantity'})">
                                            ZERO
                                        </div>
                                        <div class="picking-button  border-l md:border-l-0 rounded-l md:rounded-none"
                                             v-on:click="decreaseLineItemPickingValues({val: 1, item:'back_order_quantity'})">
                                            -
                                        </div>
                                        <div class="border border-orange-400 border-l-0 flex-1"><input
                                                v-model.number="selectedProduct.line_item.back_order_quantity"
                                                v-on:change="updateOrderLineItem"
                                                class="w-full h-full text-center bg-gray-100" type="text"></div>
                                        <div class="picking-button"
                                             v-on:click="increaseLineItemPickingValues({val: 1, item:'back_order_quantity'})">
                                            +
                                        </div>
                                        <div class="picking-button rounded-r w-20"
                                             v-on:click="increaseLineItemPickingValues({val: maxAvailableToPack, item:'back_order_quantity'})">
                                            MAX
                                        </div>
                                    </div>
                                </div>
                                <div class="sm:flex mx-auto">
                                    <div class="flex-1 md:py-5 text-xl uppercase text-red-600">Cancelled:</div>
                                    <div class="sm:w-3/4 flex p-1">
                                        <div class="picking-button rounded-l border-l hidden md:block"
                                             v-on:click="decreaseLineItemPickingValues({val: 0, item:'cancelled_quantity'})">
                                            ZERO
                                        </div>
                                        <div class="picking-button border-l md:border-l-0 rounded-l md:rounded-none"
                                             v-on:click="decreaseLineItemPickingValues({val: 1, item:'cancelled_quantity'})">
                                            -
                                        </div>
                                        <div class="border border-orange-400 border-l-0 flex-1"><input
                                                v-model.number="selectedProduct.line_item.cancelled_quantity"
                                                v-on:change="updateOrderLineItem"
                                                class="w-full h-full text-center bg-gray-100" type="text"></div>
                                        <div class="picking-button"
                                             v-on:click="increaseLineItemPickingValues({val: 1, item:'cancelled_quantity'})">
                                            +
                                        </div>
                                        <div class="picking-button rounded-r w-20"
                                             v-on:click="increaseLineItemPickingValues({val: maxAvailableToPack, item:'cancelled_quantity'})">
                                            MAX
                                        </div>
                                    </div>
                                </div>
                                <div class="sm:flex mx-auto">
                                    <div class="flex-1 md:py-5 text-xl uppercase">Boxed:</div>
                                    <div class="sm:w-3/4 flex p-1">
                                        <div class="picking-button rounded-l border-l hidden md:block"
                                             v-on:click="decreaseLineItemPickingValues({val: 0, item:'boxes'})">ZERO
                                        </div>
                                        <div class="picking-button border-l md:border-l-0 rounded-l md:rounded-none"
                                             v-on:click="decreaseLineItemPickingValues({val: 1, item:'boxes'})">
                                            -
                                        </div>
                                        <div class="border border-orange-400 border-l-0 flex-1">
                                            <input
                                                    v-model.number="selectedProduct.line_item.boxes"
                                                    v-on:change="updateOrderLineItem"
                                                    class="w-full h-full text-center bg-gray-100" type="text"></div>
                                        <div class="picking-button rounded-r"
                                             v-on:click="increaseLineItemPickingValues({val: 1, item:'boxes'})">+
                                        </div>
                                        <div class="w-20">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </content-box>
                </div>

                <div class="flex">
                    <router-link
                            :to="prevProduct==null ? '/order-product/' + selectedProduct.line_item.id : '/order-product/' + prevProduct">
                        <div class="btn" :class="{'disabled':prevProduct==null}">
                            <font-awesome-icon icon="chevron-circle-left" size="lg"/>
                        </div>
                    </router-link>
                    <router-link :to="nextProduct==null ? '/order/' + order.id : '/order-product/' + nextProduct">
                        <div class="btn">
                            <template v-if="nextProduct!=null">
                                <font-awesome-icon icon="chevron-circle-right" size="lg"/>
                            </template>
                            <template v-else>Overview</template>
                        </div>
                    </router-link>
                    <div class="btn flex-1" :class="{'disabled':printDisabled===true}" @click="printerSend">
                        <font-awesome-icon icon="print" size="lg" class="sm:mr-5"/>
                        <span class="hidden sm:inline-block"> PRINT</span>
                    </div>
                </div>

            </div>

        </div>

    </layout>
</template>

<script>
    import {mapActions, mapState, mapGetters} from 'vuex'
    import Layout from "../components/layout";
    import ContentBox from "../components/contentBox";
    import config from "../config.js";

    export default {
        name: "orders",
        components: {ContentBox, Layout},
        data() {
            return {
                config: config,
                updateTimeout: null
            }
        },
        created() {
            this.getProduct(this.$route.params.id);
        },
        beforeRouteUpdate(to, from, next) {
            this.getProduct(to.params.id);
            next()
        },
        methods: {
            ...mapActions('order/selectedProduct', ['displayNotEnoughStockNotification', 'getProduct', 'markSelectedProductNotes', 'updateReplyingProduct', 'increaseLineItemPickingValues', 'decreaseLineItemPickingValues', 'updateOrderLineItem', 'setOrderLineItem']),
            ...mapActions('printer', ['sendToPrinter']),
            printerSend() {
                if (this.printDisabled) {
                    return false;
                }

                if (this.enoughStock == false && this.notEnoughStockNotification != true) {
                    this.displayNotEnoughStockNotification();
                    return false;
                }
                if (this.selectedProduct.line_item.packed_quantity) {

                    var stickerData = {
                        orderID: this.order.orderID,
                        partNumber: this.selectedLineProduct.SKU,
                        ordered: this.selectedProduct.line_item.quantity,
                        sent: this.selectedProduct.line_item.packed_quantity,
                        backOrdered: this.selectedProduct.line_item.back_order_quantity,
                        cancelled: this.selectedProduct.line_item.cancelled_quantity,
                        bags: this.selectedProduct.line_item.boxes,
                        customerPartNumber: this.selectedProduct.product.customerProductRef ? this.selectedProduct.product.customerProductRef.ref : null,
                        productTitle: this.selectedLineProduct.name,
                        bin: this.selectedLineProduct.Bin_location.split('-')
                    }

                    this.sendToPrinter(stickerData)

                }
                if (this.nextProduct != null) {
                    this.$router.push({path: this.nextProduct})
                }

            }
        },
        computed: {
            ...mapState('order', ['order']),
            ...mapState('order/selectedProduct', ['notEnoughStockNotification', 'loading', 'status', 'selectedProduct', 'selectedProductExtraInfo', 'lineItemUpdateStatus']),
            ...mapState('printer', ['printer']),
            ...mapGetters('order/selectedProduct', ['enoughStock', 'selectedLineProduct', 'confirmedProductNotes', 'maxAvailableToPack', 'packed', 'printDisabled']),
            ...mapGetters('order', ['nextProduct', 'prevProduct']),
            title: function () {
                return this.selectedLineProduct != null ? 'Picking Product<br>' + this.selectedLineProduct.SKU : 'Loading'
            },
            backButtonLink: function () {
                return this.order != null ? '/order/' + this.order.id : null;
            }
        }
    }
</script>

<style lang="scss">

    .picking-button, .picking-input {
        @apply py-4 px-6 border border-orange-400 border-l-0 whitespace-no-wrap;
    }

    .picking-input {
        @apply p-0;
    }

    .error input {
        @apply bg-red-600 text-white;
    }

    .related-product {
        .icon {
            @apply text-gray-300;
        }

        &.complete {
            @apply border-green-600 text-green-600;
            .icon {
                @apply text-green-600;
            }
        }
    }
</style>