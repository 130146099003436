import Vue from 'vue'
import {store} from "../index";
/*
http: {
        root: 'https://3dgroupuk.com/api',
        headers: {
            APIKEY: 'sssdafsdfseqfw'
            //Authorization: 'Basic YXBpOnBhc3N3b3Jk'
        }
    }
 */
export default {
    get(url, request) {
        Vue.http.headers.common['Content-type'] = "application/json";
        Vue.http.headers.common['APIKEY'] = process.env.VUE_APP_API_KEY;
        Vue.http.headers.common['USERID'] = store.state.account.user.id;
        Vue.http.headers.common['AUTHTOKEN'] = store.state.account.user.authToken;

        return Vue.http.get(process.env.VUE_APP_API_URL + url, request)
            .then((response) => Promise.resolve(response))
            .catch((error) => Promise.reject(error));
    },
    post(url, request) {
        Vue.http.headers.common['APIKEY'] = process.env.VUE_APP_API_KEY;
        Vue.http.headers.common['USERID'] = store.state.account.user.id;
        Vue.http.headers.common['AUTHTOKEN'] = store.state.account.user.authToken;
        Vue.http.headers.common['Access-Control-Allow-Origin'] = process.env.VUE_APP_URL;
        Vue.http.headers.common['Access-Control-Allow-Methods'] = "DELETE, POST, GET, OPTIONS";
        Vue.http.headers.common['Access-Control-Allow-Headers'] = "*";
        Vue.http.headers.common['Content-type'] = "application/json";

        return Vue.http.post(process.env.VUE_APP_API_URL + url, request)
            .then((response) => Promise.resolve(response))
            .catch((error) => Promise.reject(error));
    },
    patch(url, request) {
        return Vue.http.patch(url, request)
            .then((response) => Promise.resolve(response))
            .catch((error) => Promise.reject(error));
    },
    delete(url, request) {
        return Vue.http.delete(url, request)
            .then((response) => Promise.resolve(response))
            .catch((error) => Promise.reject(error));
    }
}