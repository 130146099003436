import Vue from 'vue';
import Vuex from 'vuex';

import { alert } from './alert.module';
import { account } from './account.module';
import { orders } from './orders.module';
import { order } from "./order/order.module";
import { printer } from './printer.module';
import { settings } from "./settings.module";

Vue.use(Vuex);

export const store = new Vuex.Store({
    strict: process.env.NODE_ENV !== 'production',
    modules: {
        alert,
        account,
        orders,
        order,
        printer,
        settings
    }
});