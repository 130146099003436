import {selectedProduct} from "./selectedProduct.module";
import api from "../utils/api";

const state = {
    loading: false,
    status: false,
    order: null,
    notesVerified: {
        customerNotes: false,
        accountNotes: false,
        orderNotes: false,
    },
};

const actions = {
    getOrder({dispatch, commit}, id) {
        commit('gettingOrder')
        api.get('/orders/view', {params: {'id': id}})
            .then(response => {
                commit('gotOrder', response.body.Results)

            }).catch(error => {

            dispatch('alert/error', 'Error fetching order', {root: true});
            state.status = 'error';
            console.log(error)
        });
    },
    completeOrder({dispatch, commit, getters}, id) {
        commit('updatingOrder');
        return new Promise((resolve, reject) => {
            if (getters.canCompleteOrder == true) {
                api.post('/orders/completePicking', {'id': id})
                    .then(response => {
                        if (response.body.Results.success == false) {
                            dispatch('alert/error', 'Error completing picking', {root: true});
                            commit('updatedOrder')
                        } else {
                            resolve(response)
                            commit('updatedOrder')
                        }
                    })
                    .catch((error) => {
                        console.log(error)
                        dispatch('alert/error', 'Error updating order', {root: true});
                        commit('updatedOrder')
                    });
            } else {
                commit('updatedOrder')
                reject('Order not possible to complete')
            }
        });
    },
    uncompletePicking({dispatch, commit, getters}, id) {
        commit('updatingOrder');
        return new Promise((resolve, reject) => {
            if (getters.canUnCompletePicking == true) {

                api.post('/orders/unCompletePicking', {'id': id})
                    .then(response => {
                        if (response.body.Results.success == false) {
                            dispatch('alert/error', 'Error uncompleting picking', {root: true});
                            commit('updatedOrder')
                        } else {
                            commit('gotOrder', response.body.Results.order)
                            resolve(response)
                            commit('updatedOrder')
                        }
                    })
                    .catch(() => {
                        dispatch('alert/error', 'Error updating order', {root: true});
                        commit('updatedOrder')
                    });
            } else {
                commit('updatedOrder')
                reject('It is not possible to mark order status as picking')
            }
        });
    },
    verifyNote({commit}, noteName) {
        commit('verifyNote', {'name': noteName, 'value': !state.notesVerified[noteName]});
    }
};

const mutations = {
    verifyNote(state, payback) {
        state.notesVerified[payback.name] = payback.value;
    },
    gettingOrder(state) {
        state.loading = true;
        state.status = false;
    },
    gotOrder(state, payback) {
        state.loading = false;
        state.status = 'loaded';
        state.order = payback;
        state.notesVerified = {
            customerNotes: false,
            accountNotes: false,
            orderNotes: false,
        };
    },
    updatingOrder(state) {
        state.loading = true;
    },
    updatedOrder(state) {
        state.loading = false;
    }
};

const getters = {
    prevProduct: state => {
        if (state.selectedProduct.selectedProduct == null) {
            return null;
        }
        var result = state.order.items.find(obj => {
            return obj.id === state.selectedProduct.selectedProduct.line_item.id
        })
        if (typeof state.order.items[state.order.items.indexOf(result) - 1] != 'undefined') {
            return state.order.items[state.order.items.indexOf(result) - 1].id;
        } else {
            return null;
        }
    },
    nextProduct: state => {
        if (state.selectedProduct == null) {
            return null;
        }
        var result = state.order.items.find(obj => {
            return obj.id === state.selectedProduct.selectedProduct.line_item.id
        })
        if (typeof state.order.items[state.order.items.indexOf(result) + 1] != 'undefined') {
            return state.order.items[state.order.items.indexOf(result) + 1].id;
        } else {
            return null;
        }
    },
    canCompleteOrder: (state, getters) => {
        return (getters.isNotesVerified == true && state.order.itemsPicked == state.order.itemsRequiredPicking && state.order.editable == true) ? true : false;
    },
    canUnCompletePicking: state => {
        return state.order.statusID >= 3 && state.order.statusID < 8
    },
    countDifferentNotes: state => {
        var count = 0;
        if (state.order.customer_notes != null) {
            count++
        }
        if (state.order.order_notes != null) {
            count++
        }
        if (state.order.accountNotes.length > 0) {
            count++
        }
        return count
    },
    isNotesVerified: state => {
        if (state.order.customer_notes && state.notesVerified.customerNotes == false) {
            return false
        }
        if (state.order.order_notes && state.notesVerified.orderNotes == false) {
            return false
        }
        if (state.order.accountNotes.length > 0 && state.notesVerified.accountNotes == false) {
            return false
        }
        return true;
    }
};

export const order = {
    namespaced: true,
    state,
    actions,
    mutations,
    getters,
    modules: {
        selectedProduct,
    }
};