<template>
    <layout>
        <div class="w-full z-10 -mt-6 px-10 py-0">
            <div class="flex w-full flex-wrap">
                <div class="pb-2 pr-2 text-4xl text-center h-48 w-48">
                    <router-link class="navigate-right" :to="'orders'">
                    <content-box title="Current Orders" :vertically-align="true" :absolute-center="true">
                        {{list.length}}
                    </content-box>
                    </router-link>
                </div>
            </div>
        </div>
    </layout>
</template>

<script>
    import Layout from "../components/layout";
    import {mapActions, mapState} from 'vuex'
    import contentBox from "../components/contentBox";

    export default {
        name: "index",
        components: {Layout,contentBox},
        methods: {
            ...mapActions('orders', ['getOrders']),
            updateOrders(hideLoader) {
                this.getOrders(hideLoader);
            },
        },
        beforeRouteEnter (to, from, next) {
            next(vm => {
                vm.updateOrders(false)
                vm.orderRefreshTimer = setInterval(()=> { vm.updateOrders(false) } , 30000)
            });
        },
        beforeRouteLeave (to, from, next) {
            clearInterval(this.orderRefreshTimer);
            next();
        },
        computed: {
            ...mapState('orders', ['list']),
        }
    }
</script>

<style scoped>

</style>