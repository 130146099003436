<template>
    <div v-if="alert.message" class="alert" :class="alert.type">
        <div class="body">
            <div class="text-center">
                <div class="rounded-full bg-white w-24 h-24 mx-auto" v-if="alert.icon">{{alert.icon}}</div>
                <p class="p-4">{{alert.message}}</p>
            </div>

            <div class="w-full flex">
                <div class="p-2 flex-auto text-right" v-if="alert.type == 'confirm'">
                    <font-awesome-layers class="fa-3x">
                        <font-awesome-icon :icon="['far', 'circle']"/>
                        <font-awesome-icon icon="times" transform="shrink-10"/>
                    </font-awesome-layers>
                </div>
                <div class="p-2 flex-auto" v-on:click="clearAlert" :class="{'text-center': alert.type != 'confirm'}">
                    <font-awesome-layers class="fa-3x">
                        <font-awesome-icon :icon="['far', 'circle']"/>
                        <font-awesome-icon icon="check" transform="shrink-10"/>
                    </font-awesome-layers>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapState, mapActions} from 'vuex'

    export default {
        name: "alert",
        methods: {
            ...mapActions({
                clearAlert: 'alert/clear'
            })
        },
        computed: {
            ...mapState({
                alert: state => state.alert
            })
        },
        watch: {
            $route(to, from) {
                // clear alert on location change
                to
                from
                this.clearAlert();
            }
        }
    }
</script>

<style lang="scss">
    .alert {
        @apply text-orange-600 fixed p-10 px-24 w-screen flex max-h-screen overflow-scroll z-30;
        .body {
            @apply bg-gray-100 flex-1 rounded-lg p-10 shadow-xl overflow-scroll border-b-4 border-t-4;
        }
        &.alert-success {
            @apply text-green-600;
            .body {
                @apply border-green-600;
            }
        }
        &.alert-danger {
            @apply text-red-600;
            .body {
                @apply border-red-600;
            }
        }

    }

</style>