<template>
    <layout title="Orders To Pick" back-button="/"
            :loading="loading==true" :updated="lastUpdated" v-on:scrolling="handleScroll">
        <template v-slot:header v-if="filterOpen">
            <div class="px-10 min-h-32 pb-5">
                <div class="bg-white shadow-inner p-4 rounded-lg -mt-5 relative">
                    <div class="box-close" v-on:click="filterOpen = false"> <font-awesome-icon icon="times" /></div>
                    <h3 class="text-orange-500 -mt-2 mb-2">Filter</h3>
                    <hr class="pb-2">
                    <p class="text-xs pb-1 pl-1">Go to specific Order...</p>
                    <div class="flex">
                        <input placeholder="Order ID" class="px-2 border shadow-inner rounded mr-2" v-model="filter.orderID">
                        <div class="btn btn-xs btn-no-shadow success" v-on:click="goToOrder">Go</div>
                    </div>
                </div>
            </div>
        </template>
        <div class="search-icon" :class="[{'scrolling':scrolling},{'hidden':filterOpen}]" v-on:click="filterOpen = true">
            <font-awesome-icon icon="search" size="lg"/>
        </div>
        <div class="content z-10 p-4 pt-0" :class="{'mt-5':filterOpen,'-mt-6':!filterOpen}">
            <div v-for="order in list" :key="order.id">
                <router-link class="navigate-right" :to="'order/' + order.id">
                    <div class="box">
                        <div class="bg-white w-full mb-2 flex content">
                            <div class=" bg-yellow-500 rounded-br-lg -ml-2 -mt-2 h-6 w-6 text-white px-1 mr-2"
                                 v-if="order.method == 7">
                                <font-awesome-icon icon="cubes"/>
                            </div>
                            <div class="w-1/2">

                                <div class="">
                                    <div class="truncate">{{order.orderID}} <span
                                            class="text-gray-600">{{order.companyName}}</span></div>
                                </div>
                                <div class="text-sm">
                                    <time-ago :refresh="true" :datetime="order.orderDate" :long="true"></time-ago>
                                    <div v-if="order.pickingBy.length>0"
                                         class="text-orange-600 md:inline-block md:pl-2"><b>Picker(s):</b>
                                        <span v-for="(pickingStaff,key) in order.pickingBy" :key="pickingStaff.id">
                                        {{pickingStaff.initials}}<template
                                                v-if="key != Object.keys(order.pickingBy).length - 1">, </template>
                                    </span>
                                    </div>
                                </div>
                            </div>
                            <div class="flex-1 text-right flex">
                                <div class="flex-1">
                                    <div>{{order.status}} <span class="text-gray-600">{{order.itemsPicked}} of {{order.itemsRequiredPicking}}</span>
                                    </div>
                                    <div class="text-sm">{{order.deliveryMethod}}</div>
                                </div>
                                <div class="box-status" v-if="order.itemsPicked == order.itemsRequiredPicking">
                                    <span class="text-green-600"><font-awesome-icon icon="check-circle"/></span>
                                </div>
                                <div class="box-status"
                                     v-if="order.itemsPicked > 0 && order.itemsPicked != order.itemsRequiredPicking">
                                    <span class="text-yellow-500"><font-awesome-icon icon="people-carry"/></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </router-link>
            </div>
        </div>
    </layout>
</template>

<script>
    import {mapActions, mapState} from 'vuex'
    import Layout from "../components/layout";
    import TimeAgo from 'vue2-timeago'
    import router from "../_helpers/router";

    export default {
        name: "orders",
        components: {Layout, TimeAgo},
        created() {
            this.handleScroll()
        },
        data() {
            return {
                state: null,
                lastUpdated: null,
                orderRefreshTimer: null,
                scrolling: false,
                scrollTimeout: null,
                filterOpen: false,
                filter: {
                    orderID:null
                }
            }
        },
        methods: {
            ...mapActions('orders', ['getOrders']),
            updateOrders(hideLoader) {
                this.getOrders(hideLoader).then(() => {
                    this.lastUpdated = new Date();
                });
            },
            handleScroll() {
                this.scrolling = true;
                clearTimeout(this.scrollTimeout);
                this.scrollTimeout = setTimeout(() => {
                    this.scrolling = false;
                }, 1000)
            },
            goToOrder(){
                router.push('/order/' + this.filter.orderID);
            }
        },
        beforeRouteEnter(to, from, next) {
            next(vm => {
                vm.updateOrders(false)
                vm.orderRefreshTimer = setInterval(() => {
                    vm.updateOrders(false)
                }, 30000)
            });
        },
        beforeRouteLeave(to, from, next) {
            clearInterval(this.orderRefreshTimer);
            next();
        },
        computed: {
            ...mapState('orders', ['list', 'loading']),
        }
        ,

    }
</script>

<style lang="scss">
    .search-icon {
        @apply bg-orange-500 shadow-lg fixed rounded-r-full p-3 pr-4 text-white;
        background-color: rgba(237, 137, 54, 0.9);
        transition: background-color 0.5s linear;
        top: 150px;

        &.scrolling {
            background-color: rgba(237, 137, 54, 0.1);
        }
    }

    .box-close {
        @apply h-10 w-10 bg-red-600 text-white flex items-center justify-center shadow-inner cursor-pointer absolute top-0 right-0;
        -webkit-border-bottom-left-radius: 30px;
        -moz-border-radius-bottomleft: 30px;
        border-bottom-left-radius: 30px;
        border-top-right-radius:0.5em;
    }

    .box-status {
        @apply shadow-inner p-2 bg-gray-200 -mt-4 -mr-2 -mb-4 ml-2 flex items-center justify-center;
    }
    .success {
        .box-status {
            @apply bg-green-200;
        }
    }
</style>