<template>
    <layout title="Settings">
        <div class="p-10">
            <table class="w-full">
                <tr>
                    <td class="label">Printer Set Status</td>
                    <td>{{printerStatus}}</td>
                </tr>
                <tr>
                    <td class="label">Printer Details</td>
                    <td>
                        <template v-if="printer != null">
                            <b>UID:</b> {{printer.uid}}<br>
                            <b>Name:</b> {{printer.name}}<br>
                            <b>Connection:</b> {{printer.connection}}<br>
                            <b>Manufacturer:</b> {{printer.manufacturer}}<br>
                        </template>
                    </td>
                </tr>
                <tr>
                    <td class="font-bold">Sticker Offsets:</td>
                    <td>
                        x:<input class="p-2 w-20" type="number" placeholder="x" v-model="stickerOffsetX"/>
                        y:<input class="p-2 w-20" type="number" placeholder="y" v-model="stickerOffsetY"/>
                    </td>
                </tr>
                <tr>
                    <td class="font-bold">Idle Timeout</td>
                    <td>
                        <input class="p-2 w-20" type="number" placeholder="Idle Timeout" v-model="idleTimeout"/>
                    </td>
                </tr>
            </table>
        </div>
        <div class="px-10">
            <button class="btn" v-on:click="$store.dispatch('printer/clearDefaultPrinter')">Clear Printer</button>
            <button class="btn" v-on:click="$store.dispatch('printer/setDefaultPrinter')">Set Default Printer</button>
            <button class="btn" v-on:click="$store.dispatch('settings/saveSettings')">Save Settings</button>
        </div>
    </layout>
</template>

<script>
    import Layout from "../components/layout";
    import {mapState, mapGetters} from 'vuex'

    export default {
        name: "index",
        components: {Layout},
        computed: {
            ...mapState('settings', ['printer']),
            ...mapGetters('settings', ['printerStatus']),
            stickerOffsetX: {
                get () {
                    return this.$store.state.settings.stickerOffsetX
                },
                set (value) {
                    this.$store.commit('settings/saveValue', {label:'stickerOffsetX', value:value})
                }
            },
            stickerOffsetY: {
                get () {
                    return this.$store.state.settings.stickerOffsetY
                },
                set (value) {
                    this.$store.commit('settings/saveValue', {label:'stickerOffsetY', value:value})
                }
            },
            idleTimeout: {
                get () {
                    return this.$store.state.settings.idleTimeout
                },
                set (value) {
                    this.$store.commit('settings/saveValue', {label:'idleTimeout', value:value})
                }
            }
        }
    }
</script>

<style scoped lang="scss">
    td {
        @apply border border-gray-500 p-2;
        &.label {
            @apply font-bold;
        }
    }
</style>