import { userService } from '../_services/user.service';
import router from '../_helpers/router';

const user = JSON.parse(localStorage.getItem('user'));

const state = user
    ? { status: { loggedIn: true }, user:user }
    : { status: {}, user: null };

const actions = {
    login({dispatch, commit}, { username, password, redirect }) {
        commit('loginRequest', { username });

        userService.login(username, password)
            .then(
                user => {
                    commit('loginSuccess', user);
                    router.push(redirect).catch(() => {});
                },
                error => {
                    commit('loginFailure', error);
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
    logout({ commit }) {
        commit('logout');
    },
};

const mutations = {
    loginRequest(state, user) {
        state.status = { loggingIn: true };
        state.user = user;
    },
    loginSuccess(state, user) {
        state.status = { loggedIn: true };
        state.user = user;
        if(process.env.VUE_APP_REFERESH_LOGOUT === 'false') {
            localStorage.setItem('user', JSON.stringify(user));
        }
    },
    loginFailure(state) {
        state.status = {};
        state.user = null;
    },
    logout(state) {
        localStorage.removeItem('user');
        state.status = {};
        state.user = null;
        console.log('Mutation: User logout')
    },
    registerRequest(state, user) {
        user
        state.status = { registering: true };
    },
    registerSuccess(state, user) {
        user
        state.status = {};
    },
    registerFailure(state, error) {
        error
        state.status = {};
    }
};

export const account = {
    namespaced: true,
    state,
    actions,
    mutations
};