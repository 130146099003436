import api from "../utils/api";
import Vue from 'vue';

const state = {
    loading: false,
    status: null,
    lineItemUpdateStatus: null,
    selectedProduct: null,
    selectedProductExtraInfo: {
        notesVerified: false,
        requiredExtraProducts: {},
    },
    selectedProductReadyToPrint: true,
    updateTimeout: null,
    notEnoughStockNotification: null
};

const actions = {
    getProduct({dispatch, commit, getters}, id) {
        commit('gettingOrderProduct')
        api.get('/orders/productView', {params: {'id': id}})
            .then(response => {
                commit('order/gotOrder', response.body.Results.order, {root: true})
                commit('gotOrderProduct', {response: response.body.Results, getters: getters})
            }).catch((error) => {
            dispatch('alert/error', 'Error updating order line item', {root: true});
            state.status = null;
            console.log(error);
        });
    },
    updateReplyingProduct({commit}, id) {
        commit('updateRelatingProduct', id)
    },
    markSelectedProductNotes({commit}) {
        commit('verifySelectedProductNotes');
    },
    decreaseLineItemPickingValues({dispatch, commit}, {val, item}) {
        if (state.selectedProduct.line_item[item] > 0) {
            var value = 0;
            if (val === 0) {
                value = 0
            } else {
                value = Number(state.selectedProduct.line_item[item]) - val;
            }
            commit('changePickingValue', {
                item: item,
                value: value
            })

            if (item == 'packed_quantity' && state.selectedProduct.line_item.packed_quantity == 0 && state.selectedProduct.line_item[item] == 0) {
                commit('changePickingValue', {
                    item: 'boxes',
                    value: 0
                })
            }
            dispatch('updateOrderLineItem');
        }
    },

    increaseLineItemPickingValues({dispatch, commit, getters}, {val, item}) {
        if (getters.unconfirmedRelatedProducts > 0) {
            dispatch('alert/error', 'Please confirm you have picked the required products', {root: true});
            return false;
        }
        if (getters.confirmedProductNotes == false) {
            dispatch('alert/error', 'Please confirm notes for this product', {root: true});
            return false;
        }
        if (item == 'boxes' || getters.maxAvailableToPack > 0) {
            var value = 0;
            if (getters.maxAvailableToPack < 0) {
                value = Number(state.selectedProduct.line_item[item]) + getters.maxAvailableToPack;
            } else {
                value = Number(state.selectedProduct.line_item[item]) + val;
            }
            commit('changePickingValue', {
                item: item,
                value: value
            })

            if (item == 'packed_quantity') {
                commit('changePickingValue', {
                    item: 'boxes',
                    value: state.selectedProduct.line_item.boxes > 0 ? state.selectedProduct.line_item.boxes : 1
                })
            }

            dispatch('updateOrderLineItem');
        }
    },
    updateOrderLineItem({dispatch, commit}) {
        commit('clearTimeout')
        var timeout = setTimeout(() => {
            dispatch('setOrderLineItem');
        }, 500)
        commit('setTimeout', timeout);
    },
    setOrderLineItem({dispatch, commit, state}) {
        commit('updatingOrderLine');

        var line_item = {
            packed_quantity: state.selectedProduct.line_item.packed_quantity,
            cancelled_quantity: state.selectedProduct.line_item.cancelled_quantity,
            back_order_quantity: state.selectedProduct.line_item.back_order_quantity,
            boxes: state.selectedProduct.line_item.boxes,
        }

        api.post('/orders/updateLineItem', {'id': state.selectedProduct.line_item.id, line_item: line_item})
            .then(response => {
                if (response.body.Results.success == false) {
                    dispatch('alert/error', 'Error updating order line item', {root: true});

                    commit('failedUpdatingLineItem', response.body.Results)
                } else {
                    commit('order/gotOrder', response.body.Results.order, {root: true})
                    commit('updatedOrderLine', response.body.Results)
                }
            })
            .catch(() => {
                dispatch('alert/error', 'Error fetching product in order', {root: true});
                state.status = null;
                state.lineItemUpdateStatus = 'error'
            });
    },
    displayNotEnoughStockNotification({dispatch, commit}){
        if(state.notEnoughStockNotification != true) {
            dispatch('alert/error', 'Not enough stock for this product line', {root: true});
            commit('setNotEnoughStockNotification')
        }
    }
};

const mutations = {
    setNotEnoughStockNotification(state){
        state.notEnoughStockNotification = true;
    },
    clearTimeout(state) {
        clearTimeout(state.updateTimeout)
    },
    setTimeout(state, payload) {
        state.updateTimeout = payload
    },
    gettingOrderProduct(state) {
        state.loading = true;
        state.status = 'loading';
        state.list = [];
        state.order = null;
        state.selectedProduct = null
    },
    gotOrderProduct(state, payload) {
        state.loading = false;
        state.status = 'loaded';
        state.selectedProduct = payload.response.selectedProduct;
        state.selectedProductExtraInfo = {
            notesVerified: false,
            requiredExtraProducts: {},
        }
        state.notEnoughStockNotification = false;
    },
    verifySelectedProductNotes(state) {
        Vue.set(state.selectedProductExtraInfo, 'notesVerified', !state.selectedProductExtraInfo.notesVerified);
    },
    updateRelatingProduct(state, key) {
        Vue.set(state.selectedProductExtraInfo.requiredExtraProducts, key, !state.selectedProductExtraInfo.requiredExtraProducts[key]);
    },
    changePickingValue(state, data) {
        state.selectedProductReadyToPrint = false;
        state.selectedProduct.line_item[data.item] = data.value;
    },
    updatingOrderLine(state) {
        state.selectedProductReadyToPrint = false;
        state.loading = true;
        state.status = 'loading';
        state.lineItemUpdateStatus = 'loading';
    },
    updatedOrderLine(state, response) {
        state.status = null;
        state.loading = false;
        state.order = response.order;
        state.selectedProduct = response.selectedProduct;
        state.selectedProductReadyToPrint = true;
    },
    failedUpdatingLineItem(state, response) {
        state.status = null;
        state.lineItemUpdateStatus = 'error';
        state.order = response.order;
        state.selectedProduct = response.selectedProduct;
        state.status = false;
        state.selectedProductReadyToPrint = false;
    }
};

const getters = {
    selectedLineProduct: state => {
        if (state.selectedProduct == null) {
            return null;
        }
        if (state.selectedProduct.line_item.custom_product == 1) {
            return {
                SKU: 'CUST',
                Bin_location: 'CUST',
                custom_item: true,
                id: null,
                name: state.selectedProduct.line_item.name
            }
        } else {
            var data = state.selectedProduct.product;
            data.name = state.selectedProduct.product.product_title;
            return data
        }
    },
    packed: state => {
        return (Number(state.selectedProduct.line_item.packed_quantity) + Number(state.selectedProduct.line_item.back_order_quantity) + Number(state.selectedProduct.line_item.cancelled_quantity))
    },
    maxAvailableToPack: (state, getters) => {
        return state.selectedProduct.line_item.quantity - getters.packed;
    },
    printDisabled: (state, getters, rootState) => {
        if (rootState.order.order.locked == 0 &&
            state.selectedProductReadyToPrint === true &&
            state.selectedProduct.line_item.packed_quantity > 0 &&
            state.selectedProduct.line_item.boxes > 0 &&
            getters.maxAvailableToPack === 0) {
            return false;
        } else {
            return true;
        }
    },
    confirmedProductNotes: (state) => {
        if (state.selectedProduct.product.productPickingNotes == null) {
            return true;
        } else {
            return state.selectedProductExtraInfo.notesVerified;
        }
    },
    unconfirmedRelatedProducts: (state) => {
        if (typeof state.selectedProduct.stockLevelRelies == "undefined" || state.selectedProduct.stockLevelRelies.length == 0) {
            return 0;
        } else {
            var unselectedCount = Object.keys(state.selectedProduct.stockLevelRelies).filter((key) => {
                if (typeof state.selectedProductExtraInfo.requiredExtraProducts[key] == "undefined" || state.selectedProductExtraInfo.requiredExtraProducts[key]==false) {
                    return true;
                }
            });
            return unselectedCount.length;
        }
    },
    enoughStock: (state) => {
        if(state.selectedProduct.stockLevel.relies_on_others == 1){
            return true;
        }
        return Number(state.selectedProduct.line_item.quantity)<Number(state.selectedProduct.stockLevel.stock_level) ? true : false
    }
};

export const selectedProduct = {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
};