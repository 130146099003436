<template id="main-page">
    <div>
        <alert></alert>
        <router-view></router-view>
    </div>
</template>

<style>
    /* CSS goes here */
</style>

<script>
    import Alert from "./components/alert";

    export default {
        name: 'app',
        components: {Alert},
        props: {
            msg: String
        },
        data() {
            return {
                menuToggle: false
            }
        },
        mounted(){
            setTimeout(function(){
                // This hides the address bar:
                window.scrollTo(0, 1);
            }, 0);
        },
        onIdle() {
            if(this.$router.currentRoute.path != '/login') {
                console.log('User Idle on path:' + this.$router.currentRoute.path)

                this.$router.push({path: "/login", query: {redirect: this.$router.currentRoute.path != '/' ? this.$router.currentRoute.path : null}}).catch(() => {
                })
            }
        },
        methods: {
            toggle: function () {
                this.menuToggle = !this.menuToggle;
            },
        }
    }
</script>