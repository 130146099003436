//import api from "../_store/utils/api";
import api from "../_store/utils/api";

export const userService = {
    login,
    logout,
};

function login(username, password) {
    return api.post('/accountLogin/LoginCheck',JSON.stringify({ 'useID':username, 'pin':password })).then(handleResponse)
        .then(user => {
            // login successful if there's a jwt token in the response
            if (user.authToken) {
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                //return user;
            }

            return user;
        });
}

function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('user');
}


function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);

        if (!data.Results.auth || data.Results.auth != 'success') {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                logout();
                console.log('401')
                location.push('/login');
            }

           // const error = (data && data.message) || response.statusText;
            return Promise.reject('Error logging into your account');
        }

        return data.Results;
    });
}