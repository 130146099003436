const localSettings = JSON.parse(localStorage.getItem('settings'));

const state = localSettings != null ? localSettings : {
    printer: {},
    stickerOffsetX: 220,
    stickerOffsetY: 20,
    idleTimeout: 120
};

const actions = {
    saveSettings({commit}) {
        commit('saveToLocal')
    }
};

const mutations = {
    saveValue(state, payload) {
        state[payload.label] = payload.value
    },
    saveToLocal(state) {
        console.log('Saving settings to storage');
        localStorage.setItem('settings', JSON.stringify(state));
    },
    clearPrinter(state) {
        console.log('Clearing settings from storage')
        localStorage.removeItem('settings');
        state.printer = null;
    },
}

export const settings = {
    namespaced: true,
    state,
    actions,
    mutations,
    getters: {
        'printerStatus': state => {
            if (state.printer != null) {
                return 'Success';
            } else {
                return 'Error'
            }
        }
    }
};